import React, { Component } from "react"

import bgVideo from "./hero-video.mp4"
import videoPoster from "./video-poster.jpg"

class HeroVideo extends Component {
  constructor(props) {
    super(props)
    this.videoTimeUpdated = this.videoTimeUpdated.bind(this)
  }

  videoTimeUpdated(event) {
    this.props.handleVideoTimeUpdated(event)
  }

  render() {
    let videoOpacity = (this.props.videoEnding) ?  "0" : "1"

    return (
      <video
        className="heroVideo"
        poster={videoPoster}
        autoPlay
        muted
        playsInline
        onTimeUpdate={this.videoTimeUpdated}
        style={{ opacity: videoOpacity }}
      >
        <source src={bgVideo} type="video/mp4" />
        <p>This browser does not support the video element.</p>
      </video>
    )
  }
}

export default HeroVideo
