import React, { Component } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../layouts"
import Seo from "../components/seo"
import Nav from "../components/nav"
import Brand from "../components/logos/brand"
import HeroVideo from "../components/video/heroVideo"
import Features from "../components/features"

import imgCog from "../images/cog.svg"
import imgHouse from "../images/house-pound.svg"
import imgDocument from "../images/document.svg"
import imgTools from "../images/tools.svg"
import imgManSpeech from "../images/man-speech.svg"
import imgHouseKey from "../images/house-key.svg"
import imgSpeechBubbles from "../images/speech-bubbles.svg"

const linkBlocks = [
  {
    title: "Rent",
    symbol: imgHouse,
    url: "/residents/rent",
  },
  {
    title: "Rent statements",
    symbol: imgDocument,
    url: "/residents/rent-statements",
  },
  {
    title: "Repairs",
    symbol: imgTools,
    url: "/residents/repairs",
  },
  {
    title: "Complaints & Compliments",
    symbol: imgManSpeech,
    url: "/residents/complaints-compliments",
  },
  {
    title: "Apply for housing",
    symbol: imgHouseKey,
    url: "/residents/apply-for-housing",
  },
  {
    title: "Resident Panel",
    symbol: imgSpeechBubbles,
    url: "/residents/resident-panel",
  },
]

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      videoEnding: false,
      sloganHidden: false,
      overlayHide: false,
    }

    this.handleVideoTimeUpdated = this.handleVideoTimeUpdated.bind(this)
  }

  handleVideoTimeUpdated(event) {
    let endTime = event.target.duration
    let time = event.target.currentTime
    if (endTime - time < 2) {
      this.setState({ videoEnding: true })
    }
    if (endTime - time < 7) {
      this.setState({ sloganHidden: true })
    }
  }

  handleNavClick() {
    this.setState({
      navActive: !this.state.navActive,
    })
  }

  render() {
    // Grab the data for the homepage (WP Post ID 6)
    const data = this.props.data.allWpPage.edges
    const nav = this.props.data.allWpMenu.edges
    const seoTitle = data[0].node.title
    const seoDesc = ""

    // Get Intro from ACF data
    const prefix = data[0].node.homepage
    const slogan =
      prefix.homeSlogan === null ? "" : <h2>{prefix.homeSlogan}</h2>
    const intro = prefix.homeIntro === null ? "" : prefix.homeIntro

    let sloganOpacity = this.state.sloganHidden ? "1" : "0"

    return (
      <Layout>
        <div>
          <Seo seoTitle={seoTitle} seoDesc={seoDesc} />
          <div className="hero">
            <HeroVideo
              handleVideoTimeUpdated={this.handleVideoTimeUpdated}
              videoEnding={this.state.videoEnding}
            />
            <div className="heroInner">
              <Brand />
              <div className="heroSlogan" style={{ opacity: sloganOpacity }}>
                {slogan}
              </div>
              <Nav nav={nav} />
            </div>
          </div>

          <section className="section sectionWhite section--close-btm">
            <div className="containerFluid">
              <div className="row center-bp1">
                <div className="col-bp1-12 col-bp2-6 col-bp4-8">
                  <div className="siteintro">
                    <div dangerouslySetInnerHTML={{ __html: intro }} />
                    <br />
                    <p>
                      <Link to="/about-us">Find out more about us here</Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-bp1-12">
                  <ul className="linkBlocks">{linkBlockLoop(linkBlocks)}</ul>
                </div>
              </div>
            </div>
            <div className="features">
              <div className="containerFluid">
                <div className="row">
                  <div className="col-bp1-12">
                    <Features />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

function linkBlockLoop(items) {
  return items.map((item) => {
    return (
      <li key={item.title} className="linkBlock">
        <Link to={item.url}>
          <span className="linkBlock__img">
            <img className="linkBlock__cog" src={imgCog} alt="Cog" />
            <img
              className="linkBlock__icon"
              src={item.symbol}
              alt={item.title}
            />
          </span>
        </Link>
        <h3 className="linkBlock__title">
          <Link to={item.url}>{item.title}</Link>
        </h3>
      </li>
    )
  })
}

export default Home

// Set here the ID of the home page.
export const pageQuery = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          homepage {
            homeSlogan
            homeIntro
          }
        }
      }
    }
    allWpMenu(filter: { slug: { eq: "primary-menu" } }) {
      edges {
        node {
          name
          menuItems {
            nodes {
              databaseId
              order
              label
              path
            }
          }
        }
      }
    }
  }
`
